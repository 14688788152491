<template>
  <div>
    <a-card title="配置管理">
      <a-spin :spinning="loading">
        <a-descriptions :column="24" size="small" :bordered="true">
          <a-descriptions-item label="采购管理" :span="12">
            <a-switch v-model="form.enable_purchase_manage" />
            <Helper message="激活后开启采购管理模块" style="margin-left: 20px"></Helper>
          </a-descriptions-item>
          <a-descriptions-item label="销售管理" :span="12">
            <a-switch v-model="form.enable_sales_manage" />
            <Helper message="激活后开启销售管理模块" style="margin-left: 20px"></Helper>
          </a-descriptions-item>
          <a-descriptions-item label="生产管理" :span="12">
            <a-switch v-model="form.enable_production_manage" />
            <Helper message="激活后开启生产管理模块" style="margin-left: 20px"></Helper>
          </a-descriptions-item>
          <a-descriptions-item label="财务管理" :span="12">
            <a-switch v-model="form.enable_finance_manage" />
            <Helper message="激活后开启财务管理模块" style="margin-left: 20px"></Helper>
          </a-descriptions-item>
          <a-descriptions-item label="设备管理" :span="12">
            <a-switch v-model="form.enable_equipment_manage" />
            <Helper message="激活后开启设备管理模块" style="margin-left: 20px"></Helper>
          </a-descriptions-item>
          <a-descriptions-item label="质量管理" :span="12">
            <a-switch v-model="form.enable_inspection_manage" />
            <Helper message="激活后开启质量管理模块" style="margin-left: 20px"></Helper>
          </a-descriptions-item>
          <a-descriptions-item label="采购审批" :span="12">
            <a-switch v-model="form.enable_purchase_approval" />
            <Helper message="激活后开启采购审批" style="margin-left: 20px"></Helper>
          </a-descriptions-item>
          <a-descriptions-item label="采购审批人" :span="12">
            <a-select v-model="form.purchase_approval_users" mode="multiple" style="width: 360px">
              <a-select-option v-for="item in userItems" :key="item.id" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-descriptions-item>

          <a-descriptions-item label="采购退货审批" :span="12">
            <a-switch v-model="form.enable_purchase_return_approval" />
            <Helper message="激活后开启采购退货审批" style="margin-left: 20px"></Helper>
          </a-descriptions-item>
          <a-descriptions-item label="采购退货审批人" :span="12">
            <a-select v-model="form.purchase_return_approval_users" mode="multiple" style="width: 360px">
              <a-select-option v-for="item in userItems" :key="item.id" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-descriptions-item>

          <a-descriptions-item label="销售审批" :span="12">
            <a-switch v-model="form.enable_sales_approval" />
            <Helper message="激活后开启销售审批" style="margin-left: 20px"></Helper>
          </a-descriptions-item>
          <a-descriptions-item label="销售审批人" :span="12">
            <a-select v-model="form.sales_approval_users" mode="multiple" style="width: 360px">
              <a-select-option v-for="item in userItems" :key="item.id" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-descriptions-item>
          <!-- <a-descriptions-item label="质检审批" :span="12">
            <a-switch v-model="form.enable_inspection_approval" />
            <Helper message="激活后开启质检审批" style="margin-left: 20px"></Helper>
          </a-descriptions-item>
          <a-descriptions-item label="质检审批人" :span="12">
            <a-select v-model="form.inspection_approval_users" mode="multiple" style="width: 360px">
              <a-select-option v-for="item in userItems" :key="item.id" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-descriptions-item> -->
          <!-- <a-descriptions-item label="采购入库质检" :span="12">
            <a-switch v-model="form.enable_purchase_inspection" />
            <Helper message="激活后开启采购入库质检" style="margin-left: 20px"></Helper>
          </a-descriptions-item>
          <a-descriptions-item label="生产入库质检" :span="12">
            <a-switch v-model="form.enable_production_inspection" />
            <Helper message="激活后开启生产入库质检" style="margin-left: 20px"></Helper>
          </a-descriptions-item> -->
        </a-descriptions>
      </a-spin>

      <div style="margin-top: 12px">
        <a-button type="primary" @click="update">保存</a-button>
      </div>
    </a-card>
  </div>
</template>

<script>
import { configInfo, configUpdate } from "@/api/system";
import { userOption } from "@/api/option";

export default {
  components: {
    Helper: () => import("@/components/Helper/Helper"),
  },
  data() {
    return {
      loading: false,
      form: { purchase_approval_users: [], purchase_return_approval_users: [], sales_approval_users: [] },
      userItems: [],
    };
  },
  methods: {
    initialize() {
      this.list();
      userOption({ page_size: 999999 }).then((data) => {
        this.userItems = data.results;
      });
    },
    list() {
      this.loading = true;
      configInfo()
        .then((resp) => {
          this.form = resp;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      this.loading = true;
      configUpdate(this.form)
        .then((resp) => {
          this.form = resp;
          this.$message.success("保存成功");
        })
        .finally(() => {
          this.loading = false;
          this.$router.go(0);
        });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped></style>
